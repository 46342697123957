import {ErrorHandler, NgModule} from '@angular/core';
import * as Sentry from '@sentry/browser';

import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
import {AppComponent} from './app.component';
import {MainNavComponent} from './main-nav.component';
import {CommonModule, CustomErrorHandler} from './common/common.module';
import {NotFoundComponent} from './not-found.component';
import {ToastrModule} from 'ngx-toastr';
import {RouterModule, Routes} from '@angular/router';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthRoutingModule} from './common/auth/auth-routing.module';

Sentry.init({
  dsn: 'https://9ff04e0dff4441a3946534208b7d2401@sentry.io/1479463'
});

const ROUTES: Routes = [{
  path: '', pathMatch: 'full', redirectTo: '/license'
}, {
  path: 'user',
  loadChildren: () => import('./user/user.routing.module').then(mod => mod.UserRoutingModule)
}, {
  path: 'license',
  loadChildren: () => import('./license/license.routing.module').then(mod => mod.LicenseRoutingModule)
}];

@NgModule({
  declarations: [MainNavComponent, AppComponent, NotFoundComponent],
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      enableHtml: true,
      positionClass: 'toast-bottom-right'
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AuthRoutingModule,
    LoadingBarRouterModule,
    RouterModule.forRoot(ROUTES, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy'})
  ],
  providers: [
    {provide: ErrorHandler, useClass: CustomErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
