import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {CustomErrorHandler} from './custom-error.handler';
import {LoaderHttpXhrBackend} from './loader.http.xhr.backend';
import {HttpClientModule, HttpHandler} from '@angular/common/http';
import * as agcom from '@angular/common';
import {registerLocaleData} from '@angular/common';
import {MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import {AppAuthService} from './auth/app-auth.service';
import {DeleteConfirmButtonComponent} from './delete-confirm-button';
import {MdePopoverModule} from '@material-extended/mde';
import {PasswortValidator} from './directives/passwort-validator';
import {UppercaseDirective} from './directives/uppercase.directive';
import {DecompressBase64Directive} from './directives/decompress-base64.directive';

export {CustomErrorHandler};

registerLocaleData(localeEn, 'en-US', localeEnExtra);

@NgModule({
  declarations: [DeleteConfirmButtonComponent, PasswortValidator, UppercaseDirective, DecompressBase64Directive],
  imports: [agcom.CommonModule, FormsModule, HttpClientModule, MatDividerModule, MatCardModule, MatNativeDateModule,
    MdePopoverModule, MatButtonModule],
  providers: [
    AppAuthService, LoaderHttpXhrBackend,
    {provide: HttpHandler, useClass: LoaderHttpXhrBackend},
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: MAT_DATE_LOCALE, useValue: 'en-US'},
    {provide: LOCALE_ID, useValue: 'en-US'},
  ],
  exports: [
    agcom.CommonModule, FormsModule, RouterModule, HttpClientModule, MatDividerModule, MatButtonModule, MatMenuModule,
    MatCardModule, MatCheckboxModule, MatExpansionModule, MatChipsModule, MatDatepickerModule, MatNativeDateModule,
    MatIconModule, MatInputModule, MatPaginatorModule, MatTableModule, MatToolbarModule, MatSelectModule,
    MdePopoverModule, DeleteConfirmButtonComponent, PasswortValidator, UppercaseDirective, DecompressBase64Directive,
  ],
  entryComponents: []
})
export class CommonModule {
}
