import {Component, OnInit} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {Router} from '@angular/router';

@Component({template: ''})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private auth: AppAuthService) {
  }

  ngOnInit(): void {
    this.auth.logout().subscribe(() => this.router.navigate(['/']));
  }
}
