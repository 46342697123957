import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '../common.module';
import {AppAuthService} from './app-auth.service';
import {AuthGuard} from './auth-guard.service';
import {LoginComponent} from './login.component';
import {LogoutComponent} from './logout.component';

export {AuthGuard, AppAuthService};

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
];

@NgModule({
  declarations: [LoginComponent, LogoutComponent],
  imports: [CommonModule, RouterModule.forChild(routes)],
  providers: [AuthGuard, AppAuthService],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
