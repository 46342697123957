import {Component, ViewChild} from '@angular/core';
import {AppAuthService} from './app-auth.service';
import {Router} from '@angular/router';
import {NgModel} from '@angular/forms';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({templateUrl: './login.component.html', styleUrls: ['./login.component.scss']})
export class LoginComponent {
  @ViewChild('username', {static: true}) username: NgModel;
  data = {login: '', password: ''};
  lastError;

  constructor(private router: Router, private authService: AppAuthService) {
  }

  login() {
    this.lastError = null;
    this.authService.login(this.data.login, this.data.password)
      .pipe(catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          this.lastError = err.error.error;
          return of();
        }
        throw err;
      }))
      .subscribe(() => this.router.navigate(['/']));
  }
}
