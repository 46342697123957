import {Component} from '@angular/core';
import {AppAuthService} from './common/auth/app-auth.service';

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styles: [
    'mat-toolbar { background-color: black; padding: 5px;}',
    'mat-toolbar.mat-toolbar-single-row { height: inherit; }',
    '.mat-button { line-height: 2em; margin:0 2px; color: lightgray}',
    '.active {background-color:gray; color: whitesmoke}',
    '.mat-menu-item {height: 30px; line-height: 30px; font-size: 14px}',
    '.mat-menu-item-submenu-trigger::after {color:lightgray}',
    '.sub-Item{ padding-left: 30px}'
  ]
})
export class MainNavComponent {

  constructor(public authService: AppAuthService) {
  }

}
