import {Injectable} from '@angular/core';
import {map, mergeMap, take, tap} from 'rxjs/operators';
import {AppAuthService} from './auth/app-auth.service';
import {HttpHandler, HttpRequest, HttpResponse, HttpXhrBackend} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {gitVersion} from '../../version';

@Injectable({providedIn: 'root'})
export class LoaderHttpXhrBackend implements HttpHandler {

  version = new BehaviorSubject<{ server: string, client: string }>({server: null, client: gitVersion});

  constructor(private backend: HttpXhrBackend, private authService: AppAuthService) {
  }

  handle<T>(req: HttpRequest<any>) {
    return this.authService.user$.pipe(
      take(1),
      map(user => user ? req.headers.set('Authorization', 'Bearer ' + user.token) : req.headers),
      map(headers => new HttpRequest(req.method, req.url, req.body, {headers, responseType: req.responseType})),
      mergeMap(request => this.backend.handle(request)),
      tap((res) => {
        if (res instanceof HttpResponse) {
          this.version.next({server: res.headers.get('x-application-version'), client: gitVersion});
        }
      })
    );
  }
}
