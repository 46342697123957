<mat-toolbar>
  <a *ngIf="authService.user " mat-button routerLink="/license" routerLinkActive="active">Licenses</a>
  <a *ngIf="authService.user" mat-button routerLink="/user" routerLinkActive="active">Users</a>

  <span class="spacer"></span>

  <a *ngIf="authService.user" [matMenuTriggerFor]="user" mat-button routerLinkActive="active">
    {{ authService.user.login }}
    <span *ngIf="!authService.user.login">&lt; admin ></span>
    <i class="mat-menu-item-submenu-trigger"> </i>
    <mat-menu #user="matMenu" [overlapTrigger]=" false">
      <a mat-menu-item routerLink="/logout" routerLinkActive="active">Logout</a>
    </mat-menu>
  </a>
</mat-toolbar>

