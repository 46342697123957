import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <ngx-loading-bar [includeSpinner]="false"></ngx-loading-bar>
    <main-nav></main-nav>
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
}
